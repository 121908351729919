import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';
import { BookOverviewPageComponent } from './pages/dashboard/charts/book-overview/book-overview.component';

const routes: Routes = [
  {
    path: 'mobileview/:token',
    component: BookOverviewPageComponent,
    data: {
      tab: '',
      enableFilters: true,
      hideTopMenu: true,
    },
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth/login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'loginWithDialog',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
